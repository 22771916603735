import React, { useMemo } from 'react'
import './PersonalHonor.scss'
import ImgPersonalHonorText from '@/images/personal-honor-text.png';
import ImgMPersonalHonorBanner from '@/images/m-personal-honor-banner.png';
import ImgVipPerson from '@/images/vip-person.png';
import ImgMedalPerson from '@/images/medal-person.png';
import ImgPersonalHomepagePerson from '@/images/personal-homepage-person.png';
import ImgRoomCardPerson from '@/images/room-card-person.png';
import ImgVipBack from '@/images/vip-back.png';
import ImgVipMask from '@/images/vip-mask.png';
import ImgMedalBack from '@/images/medal-back.png';
import ImgMedalMask from '@/images/medal-mask.png';
import ImgPersonalHomepageBack from '@/images/personal-homepage-back.png';
import ImgPersonalHomepageMask from '@/images/personal-homepage-mask.png';
import ImgRoomCardBack from '@/images/room-card-back.png';
import ImgRoomCardMask from '@/images/room-card-mask.png';
import { isPc } from '@/utils/tool';
import clsx from 'clsx';
interface PropsType {
    className?: string
}
function PersonalHonor({ className }: PropsType) {
    const itemList = useMemo(() => {
        return [{
            person: ImgVipPerson,
            back: ImgVipBack,
            mask: ImgVipMask
        }, {
            person: ImgMedalPerson,
            back: ImgMedalBack,
            mask: ImgMedalMask
        }, {
            person: ImgPersonalHomepagePerson,
            back: ImgPersonalHomepageBack,
            mask: ImgPersonalHomepageMask
        }, {
            person: ImgRoomCardPerson,
            back: ImgRoomCardBack,
            mask: ImgRoomCardMask
        }]
    }, [])
    return (
        <div className={clsx('cmp-personal-honor', className)}>
            <img src={ ImgPersonalHonorText } alt='' className='cmp-personal-honor__text'></img>
            {isPc ? <div className='cmp-personal-honor__banner-box'>
                {itemList.map((item, i) =>
                    <div className='cmp-personal-honor__banner-box-item' key={i}>
                        <img src={item.person} className='cmp-personal-honor__banner-box-person' alt=''></img>
                        <div className="cmp-personal-honor__banner-box-card">
                            <img src={item.mask} className="cmp-personal-honor__banner-box-card-mask" alt=''>
                            </img>
                            <img src={item.back} className="cmp-personal-honor__banner-box-card-back" alt=''>
                            </img>
                        </div>
                    </div>
                )}
            </div> :
                <img src={ImgMPersonalHonorBanner} alt='' className='cmp-personal-honor__banner'></img>
            }
        </div>
    )
}

export default PersonalHonor