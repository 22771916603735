import React, { useCallback, useState, ChangeEvent, useMemo, useRef } from 'react'
import './LogIn.scss'
import ImgLogClose from '@/images/log-close.png';
import ImgLogo from '@/images/logo.png';
import clsx from 'clsx';
import { setAuthInfo, DESAndBase64Encrypt } from '@/utils/tool';
import { idLogin } from '@/services/home'
import { toast } from 'sonner';

interface PropsType {
    openLogIn: boolean
    setOpenLogIn: (openLogIn: boolean) => void
    loading:boolean
    setLoading: (loading: boolean) => void
}
function LogIn({ openLogIn, setOpenLogIn,loading,setLoading }: PropsType) {
    const refLoading = useRef(loading)
    refLoading.current = loading

    const [inputState, setInputState] = useState({
        inputNaadiId: '',
        inputPassword: ''
    })
    const refInputState = useRef(inputState)
    refInputState.current = inputState

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>, name: string) => {
            switch (name) {
                case 'naadiID':
                    setInputState(prev => ({
                        ...prev,
                        inputNaadiId: e.target.value
                    }))
                    break
                case 'password':
                    setInputState(prev => ({
                        ...prev,
                        inputPassword: e.target.value
                    }))
                    break
            }
        },
        []
    )
    const handleClose = useCallback(() => {
        setInputState({
            inputNaadiId: '',
            inputPassword: ''
        })
        setOpenLogIn(false)
    }, [setOpenLogIn])
    const handleLogIn = useCallback(async () => {
        if (!refInputState.current.inputNaadiId || !refInputState.current.inputPassword || refLoading.current) return
        const psw = DESAndBase64Encrypt(refInputState.current.inputPassword)
        setLoading(true)
        const { code, message, data } = await idLogin({
            erBanNo: refInputState.current.inputNaadiId,
            password: psw
        })
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setAuthInfo(data)
        setInputState({
            inputNaadiId: '',
            inputPassword: ''
        })
        setOpenLogIn(false)
        window.location.reload()
    }, [setOpenLogIn,setLoading])
    const isCanLogIn = useMemo(() => {
        let flag = true
        if (inputState.inputNaadiId.length < 1 || inputState.inputPassword.length < 6) {
            flag = false
        }
        return flag
    }, [inputState])
    return (
        <div className={clsx('cmp-log-in', { hide: !openLogIn })} onClick={(e) => e.stopPropagation()}>
            <div className='cmp-log-in__dialog' >
                <img src={ImgLogClose} alt="" className='cmp-log-in__close' onClick={handleClose} />
                <img src={ImgLogo} alt="" className='cmp-log-in__logo' />
                <input type="text" className='cmp-log-in__naadi' placeholder='إدخال معرف نادي ' onChange={(e) => handleChange(e, 'naadiID')} value={inputState.inputNaadiId} />
                <input type="text" className='cmp-log-in__password' placeholder='كلمة المرور' onChange={(e) => handleChange(e, 'password')} value={inputState.inputPassword} />
                <button className={clsx('cmp-log-in__btn', { gray: !isCanLogIn })} disabled={!isCanLogIn} onClick={handleLogIn}></button>
            </div>
        </div>
    )
}

export default LogIn