import React, { useCallback, MouseEvent } from 'react'
import './Game.scss'
import ImgGameText from '@/images/game-text.png';
import ImgGameDomino from '@/images/game-domino.png';
import ImgGameKnife from '@/images/game-knife.png';
import ImgGameLudo from '@/images/game-ludo.png';
import ImgMGameDomino from '@/images/m-game-domino.png';
import ImgMGameKnife from '@/images/m-game-knife.png';
import ImgMGameLudo from '@/images/m-game-ludo.png';
import ImgGamePhone from '@/images/m-game-phone.png';
import { isPc } from '@/utils/tool';
import clsx from 'clsx';
interface PropsType {
    className?: string
}
function Game({ className }: PropsType) {
    const handleMouseEnter = useCallback((e: MouseEvent) => {
        const videoElement = e.currentTarget.querySelector('video');

        if (videoElement) {
            videoElement.play();
        }
    }, [])
    const handleMouseLeave = useCallback((e: MouseEvent) => {
        const videoElement = e.currentTarget.querySelector('video');

        if (videoElement) {
            videoElement.pause();
        }
    }, [])
    return (
        <div className={clsx('cmp-game', className)}>
            <div className="cmp-game__left">
                <img src={ImgGameText} alt="" className='top' />
                <div className="middle">
                    <div className="item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <img src={isPc ? ImgGameLudo : ImgMGameLudo} alt="" />
                        {isPc &&
                            <div className='pc-img'></div>
                        }
                    </div>
                    <div className="item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <img src={isPc ? ImgGameDomino : ImgMGameDomino} alt="" />
                        {isPc &&
                            <div className='pc-img'></div>
                        }
                    </div>
                    <div className="item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <img src={isPc ? ImgGameKnife : ImgMGameKnife} alt="" />
                        {isPc &&
                            <div className='pc-img'></div>
                        }
                    </div>
                </div>
            </div>
            {isPc && <div className="cmp-game__right">
                <img src={ImgGamePhone} alt=''></img>
            </div>}
        </div>
    )
}

export default Game