import { memo, useEffect, useMemo, useState } from 'react'
import './Nav.scss'
import ImgHomeTitle from '@/images/home-title.png';
import ImgRoomTitle from '@/images/room-title.png';
import ImgGameTitle from '@/images/game-title.png';
import ImgPersonalHonorTitle from '@/images/personal-honor-title.png';
import ImgCustomMadeTitle from '@/images/custom-made-title.png';
import ImgOne from '@/images/one.png';
import ImgTwo from '@/images/two.png';
import ImgThree from '@/images/three.png';
import ImgFour from '@/images/four.png';
import ImgFive from '@/images/five.png';
import ImgOneActivate from '@/images/one-activate.png';
import ImgTwoActivate from '@/images/two-activate.png';
import ImgThreeActivate from '@/images/three-activate.png';
import ImgFourActivate from '@/images/four-activate.png';
import ImgFiveActivate from '@/images/five-activate.png';
import clsx from 'clsx';

interface PropsType {
    selectNav: number
    setSelectNav: (selectNav: number) => void
}
function Nav({ selectNav, setSelectNav }: PropsType) {
    const [rotationAngle, setRotationAngle] = useState(46);
    const numImgList = useMemo(() => {
        return [ImgOne, ImgTwo, ImgThree, ImgFour, ImgFive]
    }, [])
    const activateNum = useMemo(() => {
        switch (selectNav) {
            case 1:
                return ImgOneActivate
            case 2:
                return ImgTwoActivate
            case 3:
                return ImgThreeActivate
            case 4:
                return ImgFourActivate
            case 5:
                return ImgFiveActivate
        }
    }, [selectNav])
    const activateTitle = useMemo(() => {
        switch (selectNav) {
            case 1:
                return ImgHomeTitle
            case 2:
                return ImgRoomTitle
            case 3:
                return ImgGameTitle
            case 4:
                return ImgPersonalHonorTitle
            case 5:
                return ImgCustomMadeTitle
        }
    }, [selectNav])

    useEffect(() => {
        // 每次旋转36度
        // 数字1:46，数字2:10，数字3:-26，数字4：-62，数字5：-98
        switch (selectNav) {
            case 1:
                setRotationAngle(46);
                break
            case 2:
                setRotationAngle(10);
                break
            case 3:
                setRotationAngle(-26);
                break
            case 4:
                setRotationAngle(-62);
                break
            case 5:
                setRotationAngle(-98);
                break
        }
    }, [selectNav])
    return (
        <div className='cmp-nav'>
            <div className='cmp-nav-pc'>
                <img src={activateTitle} className={clsx('cmp-nav-pc__title', { 'cmp-nav-pc__title-long': selectNav > 3 })} alt=''></img>
                <div className='cmp-nav-pc__fog'></div>
                <div className='cmp-nav-pc__nav'
                    style={{
                        transform: `rotate(${rotationAngle}deg)`,
                    }}
                >
                    {numImgList.map((url, i) =>
                        <div className='img-box' key={i} onClick={() => setSelectNav(i + 1)} >
                            <img src={selectNav === i + 1 ? activateNum : url} alt=''
                                style={{
                                    transform: `rotate(${-36 * (i + 1) - rotationAngle}deg)`,
                                }}
                            >
                            </img>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default memo(Nav)