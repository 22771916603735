import React from 'react'
import './PersonalHonor.scss'
import ImgPersonalHonorText from '@Channel/images/personal-honor-text.png';
import ImgMPersonalHonorBanner from '@Channel/images/m-personal-honor-banner.png';
import clsx from 'clsx';
interface PropsType {
    className?: string
}
function PersonalHonor({ className }: PropsType) {
    return (
        <div className={clsx('cmp-personal-honor', className)}>
            <img src={ImgPersonalHonorText} alt='' className='cmp-personal-honor__text'></img>
            <img src={ImgMPersonalHonorBanner} alt='' className='cmp-personal-honor__banner'></img>
        </div>
    )
}

export default PersonalHonor