import { service } from '../utils/net'

export function idLogin(params: Home.IdLoginParams):Promise<Response<Home.IdLoginInfo>> {
  return service<Response<Home.IdLoginInfo>>({
    method: 'POST',
    url: 'auth/id/login/h5',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function getApks() {
  return service<Response<Home.Download>>({
    method: "GET",
    url: "/h5doings/official/getApk"
  }) 
  .then((res) => res.data)
  .catch((e) => e)
}

// 获取app下载链接
export function getApkDownloadLink(): Promise<Response<Home.DownloadLink>> {
  return service<Response<Home.DownloadLink>>({
    method: 'GET',
    url: 'h5doings/official/getChannelApk',
  })
    .then((res) => res.data)
    .catch((e) => e)
}