import React, { useCallback, MouseEvent } from 'react'
import './Room.scss'
import ImgRoomText from '@/images/room-text.png';
import ImgRoomGiftBanner from '@/images/room-gift-banner.png';
import ImgRoomGift from '@/images/room-gift.png';
import ImgRoomCar from '@/images/room-car.png';
import ImgMRoomPhone from '@/images/m-room-phone.png';

import { isPc } from '@/utils/tool';
import Download from './Download';
import clsx from 'clsx';
interface PropsType {
    className?: string
}
function Room({ className }: PropsType) {
    const handleMouseEnter = useCallback((e: MouseEvent) => {
        const videoElement = e.currentTarget.querySelector('video');

        if (videoElement) {
            videoElement.play();
        }
    }, [])
    const handleMouseLeave = useCallback((e: MouseEvent) => {
        const videoElement = e.currentTarget.querySelector('video');

        if (videoElement) {
            videoElement.pause();
        }
    }, [])
    return (
        <div className={clsx('cmp-room', className)}>
            <div className="cmp-room__left">
                <img src={ImgRoomText} alt="" className='top' />
                {isPc && <>
                    <div className="middle" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <video loop muted src={require('@/video/gift-banner.mp4')}></video>
                        <img src={ImgRoomGiftBanner} alt="" />
                    </div>
                    <div className="bottom">
                        <div className="car" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <video loop muted src={require('@/video/car.mp4')}></video>
                            <img src={ImgRoomCar} alt="" />
                        </div>
                        <div className="gift" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <video loop muted src={require('@/video/gift.mp4')}></video>
                            <img src={ImgRoomGift} alt="" />
                        </div>
                    </div>
                </>}
            </div>
            <div className="cmp-room__right">
                <img src={ImgMRoomPhone} alt=''></img>
            </div>
            {!isPc && <Download />}
        </div>
    )
}

export default Room