import React, { useEffect, useRef } from 'react'
import "./CustomMade.scss";
import ImgCustomMadeText from '@/images/custom-made-text.png';
import ImgMCustomMadeText from '@/images/m-custom-made-text.png';
// import ImgSwiperImg1 from '@/images/swiper-img1.png';
import { isPc } from '@/utils/tool';
//@ts-ignore
import Swiper from 'swiper/bundle';
import clsx from 'clsx';

interface PropsType {
    className?: string
}
function CustomMade({ className }: PropsType) {
    const refSwiper = useRef<any>(null);

    useEffect(() => {
        // 初始化swiper
        refSwiper.current = new Swiper('.swiper2 .swiper-container', {
            slidesPerView: 5,//同时显示的slides数量
            allowTouchMove: false,//false禁止鼠标滑动切换
            centeredSlides: true,//设定为true时，当前的active slide 会居中，而不是默认状态下的居左。
            observer: true,//修改swiper自己或子元素时，自动初始化swiper
            observeParents: true,//修改swiper的父元素时，自动初始化swiper
            loop: true, // 循环模式选项
            direction: isPc ? 'horizontal' : 'vertical', // 垂直轮播
            autoplay: {
                delay: 2000,//自动切换的时间间隔，单位ms，默认3s
                stopOnLastSlide: false,
                disableOnInteraction: false,//用户操作swiper之后，是否禁止autoplay
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            // spaceBetween: 100,//slide之间设置距离（单位px）
        });

        return () => {
            // 销毁Swiper，释放浏览器内存
            refSwiper.current.destroy();
        };
    }, []);
    return (
        <div className={clsx('cmp-custom-made', className)}>
            <img src={isPc ? ImgCustomMadeText : ImgMCustomMadeText} alt='' className='cmp-custom-made__text'></img>
            <div className="swiper2">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {
                            new Array(7).fill(0).map((_, i) =>
                                <div key={i} className="swiper-slide">
                                    <div className='img-content'>
                                        <img src={require(`@/images/swiper-img${i+1}.png`)} alt="" />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </div>
        </div>
    )
}

export default CustomMade