import clsx from 'clsx'
import React, { useCallback } from 'react'
import './MLogOut.scss'
import ImgLogClose from '@/images/log-close.png';
import ImgLogo from '@/images/logo.png';
import ImgMSignOutBtn from '@/images/m-sign-out-btn.png';
import { clearAuthInfo } from '@/utils/tool';

interface PropsType {
    openMLogOut: boolean
    setOpenMLogOut: (openLogIn: boolean) => void
}
function MLogOut({ openMLogOut, setOpenMLogOut }: PropsType) {
    const handleSignOut = useCallback(() => {
        clearAuthInfo()
        window.location.reload()
    }, [])
    return (
        <div className={clsx('cmp-m-log-out', { hide: !openMLogOut })} onClick={(e) => e.stopPropagation()}>
            <div className='cmp-m-log-out__dialog' >
                <img src={ImgLogClose} alt="" className='cmp-m-log-out__close' onClick={() => setOpenMLogOut(false)} />
                <img src={ImgLogo} alt="" className='cmp-m-log-out__logo' />
                <span className='cmp-m-log-out__text'>ما إذا كنت بحاجة إلى الخروج من هذا الحساب</span>
                <img src={ImgMSignOutBtn} alt="" className='cmp-m-log-out__btn' onClick={handleSignOut} />
            </div>
        </div>
    )
}

export default MLogOut