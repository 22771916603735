import React, { memo, useMemo } from 'react'
import './MNav.scss'
import ImgMHome from '@/images/m-home-title.png';
import ImgMRoom from '@/images/m-room-title.png';
import ImgMGame from '@/images/m-game-title.png';
import ImgMHonor from '@/images/m-honor-title.png';
import ImgMMade from '@/images/m-made-title.png';
import ImgMHomeTitleAc from '@/images/home-title.png';
import ImgMRoomTitleAc from '@/images/room-title.png';
import ImgMGameTitleAc from '@/images/game-title.png';
import ImgMHonorTitleAc from '@/images/m-honor-title-ac.png';
import ImgMMadeTitleAc from '@/images/custom-made-title.png';

import ImgOne from '@/images/one.png';
import ImgTwo from '@/images/two.png';
import ImgThree from '@/images/three.png';
import ImgFour from '@/images/four.png';
import ImgFive from '@/images/five.png';
import ImgOneActivate from '@/images/one-activate.png';
import ImgTwoActivate from '@/images/two-activate.png';
import ImgThreeActivate from '@/images/three-activate.png';
import ImgFourActivate from '@/images/four-activate.png';
import ImgFiveActivate from '@/images/five-activate.png';

interface PropsType {
    selectNav: number
    setSelectNav: (selectNav: number) => void
}
function MNav({ selectNav, setSelectNav }: PropsType) {
    const itemList = useMemo(() => {
        return [{
            title: ImgMHome,
            num: ImgOne
        }, {
            title: ImgMRoom,
            num: ImgTwo
        }, {
            title: ImgMGame,
            num: ImgThree
        }, {
            title: ImgMHonor,
            num: ImgFour
        }, {
            title: ImgMMade,
            num: ImgFive
        }]
    }, [])
    const activateNum = useMemo(() => {
        switch (selectNav) {
            case 1:
                return ImgOneActivate
            case 2:
                return ImgTwoActivate
            case 3:
                return ImgThreeActivate
            case 4:
                return ImgFourActivate
            case 5:
                return ImgFiveActivate
        }
    }, [selectNav])
    const activateTitle = useMemo(() => {
        switch (selectNav) {
            case 1:
                return ImgMHomeTitleAc
            case 2:
                return ImgMRoomTitleAc
            case 3:
                return ImgMGameTitleAc
            case 4:
                return ImgMHonorTitleAc
            case 5:
                return ImgMMadeTitleAc
        }
    }, [selectNav])
    return (
        <div className='cmp-m-nav'>
            {itemList.map((item, i) =>
                <div className='cmp-m-nav__item' key={i} onClick={() => setSelectNav(i + 1)}>
                    <img src={selectNav === i + 1 ? activateTitle : item.title} alt=''></img>
                    <img src={selectNav === i + 1 ? activateNum : item.num} alt=''></img>
                </div>
            )}
        </div>
    )
}

export default memo(MNav)