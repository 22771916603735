import { useState, useEffect, useRef, useCallback } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import config from "@/config";
import ImgLogo from "@/images/logo.png";
// import ImgLogin from "@/images/login.png";
import ImgTopup from "@/images/topup.png";
// import ImgMore from "@/images/more-btn.png";
import { isPc, getAuthInfo, clearAuthInfo } from '@/utils/tool';
//@ts-ignore
import Swiper from 'swiper/bundle';
import 'swiper/css';
import SafeArea from '@/components/SafeArea';
import Home from './components/Home';
import Nav from './components/Nav';
import MNav from './components/MNav';
import Room from './components/Room';
import Game from './components/Game';
import PersonalHonor from './components/PersonalHonor';
import CustomMade from './components/CustomMade';
import LogIn from './components/LogIn';
import MLogOut from './components/MLogOut';
import { toast, Toaster } from 'sonner';
import Loading from '@/components/Loading';
import config from "@/config";
import "./index.scss";

function HomePage() {
  const [selectNav, setSelectNav] = useState(1)//轮播图切换1-5，默认1
  const refSelectNav = useRef(selectNav)
  refSelectNav.current = selectNav

  const [loading, setLoading] = useState(false)
  // const [openMore, setOpenMore] = useState<boolean>(false)
  const [openLogIn, setOpenLogIn] = useState<boolean>(false)
  const [openMLogOut, setOpenMLogOut] = useState<boolean>(false)

  //获取当前用户信息
  const [user, setUser] = useState<any>()
  const refUser = useRef(user)
  refUser.current = user

  const refSwiper = useRef<any>(null);

  const handleTopUp = useCallback(() => {
    const data = getAuthInfo()
    /* 登录过期了 */
    if(data?.expirse != null && data?.expirse < new Date().getTime()){
      toast.error('لقد انتهت صلاحية تسجيل الدخول بك')
      setTimeout(() => {
        clearAuthInfo()
        window.location.reload()
      }, 2000)
      return
    }
    const auth = data?.value
    if (auth) {
      window.location.href = config.rechargeUrl + `?u=${window.btoa(window.encodeURIComponent(auth.uid))}&t=${window.btoa(window.encodeURIComponent(auth.ticket))}`
    } else {
      setOpenLogIn(true)
    }
  }, [])

  // const handleMore = useCallback((bool: boolean) => {
  //   setOpenMore(bool)
  // }, [])

  // const handleMoreListItem = useCallback((key: number) => {
  //   switch (key) {
  //     case 1:
  //       window.location.href = config.privacyUrl
  //       break;
  //     case 2:
  //       window.location.href = config.helpUrl
  //       break;
  //     case 3:
  //       clearAuthInfo()
  //       window.location.reload()
  //       break;
  //   }
  // }, [])

  // const handleMLogOut = useCallback(() => {
  //   if (isPc) return
  //   setOpenMLogOut(true)
  // }, [])

  /* 初始化 */
  useEffect(() => {
    // 初始化swiper
    refSwiper.current = new Swiper('.swiper', {
      // allowTouchMove: false,//禁止鼠标滑动切换
      direction: 'vertical',//垂直切换
      speed: 500,//切换速度500
      mousewheel: true,//开启鼠标滚轮控制Swiper切换
      threshold: 30, //如果触摸距离小于该值滑块不会被拖动
      on: {
        slideChangeTransitionStart: function () {
          //@ts-ignore
          setSelectNav(this.activeIndex + 1)
        },
      },
    });

    // 获取用户信息
    const data = getAuthInfo()
    if (data?.value) {
      setUser(data?.value)
    }

    return () => {
      refSwiper.current?.destroy()
    }
  }, []);

  // js控制swiper切换
  useEffect(() => {
    if (refSwiper.current) {
      refSwiper.current.slideTo(selectNav - 1);
    }
  }, [selectNav])
  return (
    <HelmetProvider>
      <Helmet>
        <title>{process.env.REACT_APP_DISPLAY_NAME}</title>
        {isPc ? <meta name="viewport" content="width=device-width, initial-scale=1" /> : <meta name="viewport" content="width=device-width, viewport-fit=cover, initial-scale=1.0, user-scalable=no" />}
      </Helmet>
      <div className="pg-home">
        <header>
          {!isPc && <SafeArea position="top"></SafeArea>}
          <div className="content">
            <div className="left">
              <img src={ImgLogo} alt=""></img>
              <img src={isPc ? require(`@/images/${process.env.REACT_APP_NAME}.name.png`) : require(`@/images/${process.env.REACT_APP_NAME}.m-name.png`)} alt=""></img>
            </div>
            {/* <div className="right">
              {user ? <div className="user-info" onClick={handleMLogOut}>
                <img src={user?.avatar} alt="" />
                <span>{user?.nick}</span>
              </div> :
                <img className="login" src={ImgLogin} alt="" onClick={() => setOpenLogIn(true)}></img>
              }*/}
              <img className="top-up" src={ImgTopup} alt="" onClick={handleTopUp}></img>
              {/*<div className="more" >
                <img src={ImgMore} alt="" onClick={() => handleMore(!openMore)}></img>
                <div className={clsx("more-list", { 'open-more': openMore, 'open-more-nouser': !user && openMore })}>
                  <div className="more-list-item" onClick={() => handleMoreListItem(1)}>سياسة الخصوصية</div>
                  <div className="more-list-item" onClick={() => handleMoreListItem(2)}>اتصل بالمسؤول</div>
                  {user &&
                    <div className="more-list-item" style={{ color: '#D53100' }} onClick={() => handleMoreListItem(3)}>الخروج</div>
                  }
                </div>
              </div>
            </div> */}
          </div>
        </header>
        <nav>
          {isPc ? <Nav selectNav={selectNav} setSelectNav={setSelectNav} /> : <MNav selectNav={selectNav} setSelectNav={setSelectNav} />}
        </nav>
        <div className="swiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide"><Home /></div>
            <div className="swiper-slide"><Room /></div>
            <div className="swiper-slide"><Game /></div>
            <div className="swiper-slide"><PersonalHonor /></div>
            <div className="swiper-slide"><CustomMade /></div>
            {/* 渐隐效果：speed设为0 */}
            {/* <div className="swiper-slide"><Room className={selectNav === 2 ? 'animate__animated animate__fadeIn' : ''} /></div> */}
          </div>
        </div>
        <LogIn openLogIn={openLogIn} setOpenLogIn={setOpenLogIn} loading={loading} setLoading={setLoading} />
        <MLogOut openMLogOut={openMLogOut} setOpenMLogOut={setOpenMLogOut} />
        <Toaster position="top-center" richColors expand={true} />
        {loading && <Loading />}
        {/* <div className="pg-home-company">
          <p>广州微悦网络科技有限公司</p>
          {/* <p>MicroJoy Technology Co., Ltd.</p> }
        </div> */}
      </div>
    </HelmetProvider>
  );
}

export default HomePage;
