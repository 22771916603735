import React from 'react'
import "./Home.scss";
import ImgMText from '@Channel/images/m-home-text.png';
import ImgMPhone from '@Channel/images/m-home-phone.png';
import clsx from 'clsx';
interface PropsType {
    className?: string
}
function Home({ className }: PropsType) {
    return (
        <div className={clsx('cmp-home', className)}>
            <div className='cmp-home-content'>
                <img className='cmp-home-content__person' src={ImgMPhone}></img>
                <img className='cmp-home-content__text' src={ImgMText}></img>
            </div>
            <div className="cmp-home-download">
            </div>
        </div>
    )
}

export default Home