import './Game.scss'
import ImgGameText from '@Channel/images/game-text.png';
import ImgMGameLudo from '@Channel/images/m-game-ludo.png';
import ImgMGameDomino from '@Channel/images/m-game-domino.png';
import ImgMGameKnife from '@Channel/images/m-game-knife.png';
import clsx from 'clsx';

interface PropsType {
    className?: string
}
function Game({ className }: PropsType) {

    return (
        <div className={clsx('cmp-game', className)}>
            <div className="cmp-game__left">
                <img src={ImgGameText} alt="" className='top' />
                <div className="middle">
                    <div className="item" >
                        <img src={ImgMGameLudo} alt="" />
                    </div>
                    <div className="item" >
                        <img src={ImgMGameDomino} alt="" />
                    </div>
                    <div className="item" >
                        <img src={ImgMGameKnife} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Game