const config = {
  net: {
    host: process.env.REACT_APP_API_URL
  },
  rechargeUrl: process.env.REACT_APP_ThirdRecharge_URL,
  encryptKey: 'd7ddcfc27878144829e116c405801af6',
  privacyUrl: '',
  helpUrl: '',
  url: {
    ios: '',
    apk: process.env.REACT_APP_DOWNLOAD_ANDROID || '',
    google: '',
    huawei: ''
  }
}
export default config