import { isPc } from '@/utils/tool';
import ImgPerson from '@/images/home-person.png';
import ImgText from '@/images/home-text.png';
import ImgMText from '@/images/m-home-text.png';
import ImgMPhone from '@/images/m-home-phone.png';
import Download from './Download';
import clsx from 'clsx';
import "./Home.scss";
interface PropsType {
    className?: string
}
function Home({ className }: PropsType) {
    return (
        <div className={clsx('cmp-home', className)}>
            <div className='cmp-home-content'>
                <img className='cmp-home-content__person' src={isPc ? ImgPerson : ImgMPhone} alt='' />
                <img className='cmp-home-content__text' src={isPc ? ImgText : ImgMText} alt='' />
            </div>
            <div className="cmp-home-download">
                <Download />
            </div>
        </div>
    )
}

export default Home