import { memo, useCallback, MouseEvent, useState, useEffect } from 'react'
// import { isAndroid, isIOS, isHuawei } from '@/utils/tool';
// import ImgIos from "@/images/ios.png";
import ImgApk from "@/images/apk.png";
// import ImgHuawei from "@/images/huawei.png";
// import ImgGoogle from "@/images/google.png";
import { getApks } from '@/services/home';
import { toast } from 'sonner';
import "./Download.scss";

function Download() {
    const [apks, setApks] = useState<Home.ApkItem[]>([
        {
            key: 'officialAndApk',
            img: ImgApk,
            url: ''
        }
    ])

    const handleGetApks = useCallback(async() => {
        const { code, message, data } = await getApks()
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        const newApks = apks.map(item => (item.url = data[item.key], item))
        setApks(newApks.filter(item => item.url))
    }, [apks])

    // const download = useMemo(() => {
    //     const linkArr = [];
    //     // if (isIOS) {
    //     //     linkArr.push({
    //     //         logo: ImgIos,
    //     //         url: config.url.ios
    //     //     });
    //     // } else if (isAndroid) {
    //     //     linkArr.push({
    //     //         logo: ImgGoogle,
    //     //         url: config.url.google
    //     //     }, {
    //     //         logo: ImgApk,
    //     //         url: config.url.apk
    //     //     });
    //     // } else if (isHuawei) {
    //     //     linkArr.push({
    //     //         logo: ImgHuawei,
    //     //         url: config.url.huawei
    //     //     });
    //     // } else {
    //         linkArr.push({
    //         //     logo: ImgIos,
    //         //     url: config.url.ios
    //         // }, {
    //         //     logo: ImgGoogle,
    //         //     url: config.url.google
    //         // }, {
    //             logo: ImgApk,
    //             url: config.url.apk
    //         // }, {
    //         //     logo: ImgHuawei,
    //         //     url: config.url.huawei
    //         });
    //     // }
    //     return linkArr;
    // }, []);

    const handleDownload = useCallback(
        (url: string, e: MouseEvent<HTMLAnchorElement>) => {
            window.location.href = url;
        },
        []
    );
    
    useEffect(() => {
        handleGetApks()
    }, [])

    return (
        <div className='cmp-download'>
            {apks.map((item) => (
                <div className="cmp-download__item" key={item.key}>
                    <a
                        href={item.url}
                        download
                        onClick={(e) => handleDownload(item.url, e)}
                    >
                        <img
                            className="cmp-download__item-img"
                            src={item.img}
                            alt="item"
                        />
                    </a>
                </div>
            ))}
        </div>
    )
}

export default memo(Download)