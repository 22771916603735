import CryptoJS from 'crypto-js';
import config from '@/config';

const ua = navigator.userAgent
export const isAndroid = ua.indexOf('Android') !== -1 || ua.indexOf('Linux') !== -1
export const isIpad = !!((/macintosh|mac os x/i.test(navigator.userAgent) && window.screen.height > window.screen.width && !navigator.userAgent.match(/(iPhone\sOS)\s([\d_]+)/)) || navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/))
export const isIOS = /iphone|ipad|ipod/i.test(ua) || !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || isIpad
export const isHuawei = /Huawei|HarmonyOS/i.test(ua) || ua.includes("HuaweiBrowser")
export const isPc = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|SymbianOS|Windows Phone/i.test(ua) === false && !isIOS

// 获取用户信息
export function getAuthInfo(key = 'auth') {
    let auth = localStorage.getItem(key);
    let data: {expirse:number,value:Home.IdLoginInfo} | null = null;
    if (auth && auth.indexOf('value') > -1) {
        data = JSON.parse(auth);
    }
    if (data !== null) {
        // if (data.expirse != null && data.expirse < new Date().getTime()) {
        //     localStorage.removeItem(key);
        // } else {
        //     return data.value;
        // }
        return data
    }
    return null;
}

// 设置用户信息（默认30分钟过期）
export function setAuthInfo(value: Home.IdLoginInfo, key = 'auth', m = 60) {
    let expirse = m * 60 * 1000;//毫秒
    let data = {
        value,
        expirse: new Date().getTime() + expirse
    }
    localStorage.setItem(key, JSON.stringify(data))
}

// 清除用户信息
export function clearAuthInfo(key = 'auth') {
    localStorage.removeItem(key)
}

//des + base64加密
export function DESAndBase64Encrypt(dataToEncrypt: string) {
    const keyHex = CryptoJS.enc.Utf8.parse(config.encryptKey);
    const encrypted = CryptoJS.DES.encrypt(dataToEncrypt, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}