import React, { memo, useMemo } from 'react'
import './MNav.scss'
import ImgMHome from '@Channel/images/m-home-title.png';
import ImgMHonor from '@Channel/images/m-honor-title.png';
import ImgMGame from '@Channel/images/m-game-title.png';
import ImgMHomeTitleAc from '@Channel/images/home-title.png';
import ImgMGameTitleAc from '@Channel/images/game-title.png';
import ImgMHonorTitleAc from '@Channel/images/m-honor-title-ac.png';

import ImgOne from '@Channel/images/one.png';
import ImgTwo from '@Channel/images/two.png';
import ImgThree from '@Channel/images/three.png';
import ImgOneActivate from '@Channel/images/one-activate.png';
import ImgTwoActivate from '@Channel/images/two-activate.png';
import ImgThreeActivate from '@Channel/images/three-activate.png';

interface PropsType {
    selectNav: number
    setSelectNav: (selectNav: number) => void
}
function MNav({ selectNav, setSelectNav }: PropsType) {
    const itemList = useMemo(() => {
        return [{
            title: ImgMHome,
            num: ImgOne
        }, {
            title: ImgMGame,
            num: ImgTwo
        }, {
            title: ImgMHonor,
            num: ImgThree
        }]
    }, [])
    const activateNum = useMemo(() => {
        switch (selectNav) {
            case 1:
                return ImgOneActivate
            case 2:
                return ImgTwoActivate
            case 3:
                return ImgThreeActivate
        }
    }, [selectNav])
    const activateTitle = useMemo(() => {
        switch (selectNav) {
            case 1:
                return ImgMHomeTitleAc
            case 2:
                return ImgMGameTitleAc
            case 3:
                return ImgMHonorTitleAc
        }
    }, [selectNav])
    return (
        <div className='cmp-m-nav'>
            {itemList.map((item, i) =>
                <div className='cmp-m-nav__item' key={i} onClick={() => setSelectNav(i + 1)}>
                    <img src={selectNav === i + 1 ? activateTitle : item.title} alt=''></img>
                    <img src={selectNav === i + 1 ? activateNum : item.num} alt=''></img>
                </div>
            )}
        </div>
    )
}

export default memo(MNav)